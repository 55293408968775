import {
  Fail, Maybe, Success,
} from 'monet';

/**
 * @description Запрос на создание шаблона документа.
 * @param {string} name     Название.
 * @param {File}   template Файл шаблона документа.
 * @constructor
 */
export function CreateDocumentTemplateRequest(name, template) {
  this.toQuery = () => {
    const data = new FormData();
    data.append('name', name);
    data.append('template', template);
    return data;
  };
}

/**
 * @description Запрос на редактирование шаблона документа.
 * @param {string} name    Название.
 * @param {?File}  template Файл шаблона документа.
 * @constructor
 */
export function EditDocumentTemplateRequest(name, template, templateId) {
  this.getTemplateId = () => templateId;
  this.toQuery = () => {
    const data = new FormData();
    data.append('name', name);
    if (template && template.size) {
      data.append('template', template);
    }
    data.append('_method', 'PUT');
    return data;
  };
}

/**
 * @description Запрос на проверку шаблона документа.
 * @param {File|{name:string}}  template   Файл шаблона документа.
 * @param {number}              templateId Название.
 * @constructor
 */
export function CheckDocumentTemplateRequest(template, templateId) {
  this.getName = () => template.name;
  this.toQuery = () => {
    const data = new FormData();
    if (template && template.size) {
      data.append('template', template);
    }
    if (templateId) {
      data.append('template_id', templateId);
    }
    return data;
  };
}

/**
 * @param {Axios} axios
 */
export default function DocumentTemplateGateway(axios) {
  /**
   * @description Получить клиники.
   * @param {string} match Совпадение.
   * @param {number} page  Номер страницы.
   * @returns {Promise<Maybe>}
   */
  this.getClinics = (match, page) => axios
    .get('/api/admin/clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить шаблоны документов клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Maybe>}
   */
  this.getTemplates = (clinicId) => axios
    .get(`/api/admin/clinics/${clinicId}/templates`)
    .then(async (response) => Maybe
      .Some(
        (response.data.data || []).map((item) => ({
          fileName: item.file_name,
          id: item.id,
          name: item.name,
        })),
      ));

  /**
   * @description Создать шаблон документа.
   * @param {number}                        clinicId Идентификатор клиники.
   * @param {CreateDocumentTemplateRequest} request  Запрос.
   * @returns {Promise<Validation>}
   */
  this.create = (clinicId, request) => axios
    .post(`/api/admin/clinics/${clinicId}/templates`, request.toQuery())
    .then(
      async (response) => Success(response.data),
      async (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        if (error.isAxiosError && error.response.status === 403) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        throw error;
      },
    );

  /**
   * @description Удалить шаблон документа.
   * @param {number}                      clinicId   Идентификатор клиники.
   * @param {EditDocumentTemplateRequest} templateId Идентификатор шаблона.
   * @returns {Promise<Validation>}
   */
  this.delete = (clinicId, templateId) => axios
    .delete(`/api/admin/clinics/${clinicId}/templates/${templateId}`)
    .then(
      async (response) => Success(response.data),
      async (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        throw error;
      },
    );

  /**
   * @description Скачать шаблон.
   * @param {number} clinicId   Идентификатор клиники.
   * @param {number} templateId Идентификатор шаблона.
   * @returns {Promise<Validation>}
   */
  this.downloadTemplate = (clinicId, templateId) => axios
    .get(
      `/api/admin/clinics/${clinicId}/templates/${templateId}/download`,
      { responseType: 'blob' },
    ).then(
      async (response) => Success(response.data),
      async (error) => {
        const getResponse = async () => {
          const json = await (error.response.data || '').text();
          const data = JSON.parse(json);
          return data;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        throw error;
      },
    );

  /**
   * @description Редактировать шаблон документа.
   * @param {number}                      clinicId Идентификатор клиники.
   * @param {EditDocumentTemplateRequest} request  Запрос.
   * @returns {Promise<Validation>}
   */
  this.edit = (clinicId, request) => axios
    .post(
      `/api/admin/clinics/${clinicId}/templates/${request.getTemplateId()}`,
      request.toQuery(),
    ).then(
      async (response) => Success(response.data),
      async (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const { title } = error.response.data;
          const causes = makeCauses(error.response.data.causes);
          return Fail({ causes, title });
        }
        throw error;
      },
    );

  /**
   * @description Проверить шаблон.
   * @param {number}                       clinicId Идентификатор клиники.
   * @param {CheckDocumentTemplateRequest} request  Запрос.
   * @returns {Promise<Validation>}
   */
  this.checkTemplate = (clinicId, request) => axios
    .post(
      `/api/admin/clinics/${clinicId}/check-template`,
      request.toQuery(),
      { responseType: 'blob' },
    )
    .then(
      async (response) => Success(response.data),
      async (error) => {
        const makeCauses = (causes) => {
          const failurePairs = (causes || [])
            .map((cause) => [cause.source, cause.details]);
          return failurePairs;
        };
        const getResponse = async () => {
          const json = await (error.response.data || '').text();
          const data = JSON.parse(json);
          return data;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const response = await getResponse();
          const { title } = response;
          const causes = makeCauses(response.causes);
          return Fail({ causes, title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const response = await getResponse();
          const { title } = response;
          const causes = makeCauses(response.causes);
          return Fail({ causes, title });
        }
        throw error;
      },
    );
}
