import { Fail, Success } from 'monet';

/**
 * @description Пользователь.
 * @param {number}  id             Идентификатор.
 * @param {string}  created        Дата создания.
 * @param {string}  authorized     Дата последней авторизации.
 * @param {string}  name           Имя.
 * @param {string}  note           Заметка о пользователе.
 * @param {string}  surname        Фамилия.
 * @param {string}  patronymic     Отчество.
 * @param {string}  role           Роль.
 * @param {string}  status         Статус активации.
 * @param {string}  email          Электронный адрес.
 * @param {string}  clinicName     Название клиники.
 * @param {string}  cityName       Название города.
 * @param {string}  phone          Номер.
 * @param {any[]}   utmTags        UTM-метки.
 * @param {string}  organizationId Айди организации.
 * @constructor
 */
export function User(
  id,
  created,
  authorized,
  name,
  note,
  surname,
  patronymic,
  role,
  status,
  email,
  clinicName,
  cityName,
  phone,
  utmTags,
  organizationId,
) {
  this.id = id;
  this.created = created;
  this.authorized = authorized;
  this.name = name;
  this.note = note;
  this.surname = surname;
  this.patronymic = patronymic;
  this.role = role;
  this.status = status;
  this.email = email;
  this.clinicName = clinicName;
  this.cityName = cityName;
  this.phone = phone;
  this.utmTags = utmTags;
  this.organizationId = organizationId;
}
/**
 * @description Шлюз пользователей.
 * @param {Axios} axios
 */
export default function UserGateway(axios) {
  /**
   * @description Получить список пользователей для показа.
   * @param {number}  page        Номер страницы.
   * @param {String}  searchQuery Номер страницы.
   * @param {boolean} onlyActive  Только "активные" пользователи
   * @param {boolean} onlyOwners  Только пользователи c ролью "Владелец"
   * @param {Object}  sort        Сортировка.
   * @returns {Promise<Success|Fail>}
   */
  this.getListForView = async (
    page,
    searchQuery,
    onlyActive,
    onlyOwners,
    sort,
  ) => {
    try {
      const response = await axios.get(
        '/api/admin/users',
        {
          params: {
            page,
            search_query: searchQuery,
            active_users: onlyActive || '',
            owners: onlyOwners || '',
            sort_type: sort.type,
            sort_direction: sort.direction,
          },
        },
      );
      const {
        list, count, per_page: perPage, page_count: pageCount,
      } = response.data;
      return Success({
        list: Object.keys(list).map((key) => (
          {
            [key]: list[key].map((item) => new User(
              item.id,
              item.created,
              item.authorized,
              item.name,
              item.note,
              item.surname,
              item.patronymic,
              item.role,
              item.status,
              item.email,
              item.clinic_name,
              item.city_name,
              item.phone,
              item.utm_tags,
              item.organization_id,
            )),
          }
        )),
        count,
        perPage,
        pageCount,
      });
    } catch (err) {
      return Fail(err);
    }
  };
  this.saveUserNote = async (userId, note) => {
    try {
      const response = await axios.post('/api/admin/user/note', { userId, note });
      return Success(response.data);
    } catch (err) {
      return Fail(err);
    }
  };
  /**
   * @description Просмотр истории действий пользователя.
   * @param {Number} userId Идентификатор пользователя.
   * @returns {Promise<Success|Fail>}
   */
  this.getActionHistoryByUserId = async (userId) => {
    try {
      const response = await axios.get(`/api/admin/users/${userId}/action-history`);
      return Success(response.data);
    } catch (error) {
      return Fail(error);
    }
  };
}
