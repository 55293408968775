import { Either, Maybe } from 'monet';

/**
 * @description Шлюз MANGO OFFICE.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function MangoOfficeGateway(axios) {
  /**
   * @description Подключить интеграцию для клиники.
   * @param {string} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.enableIntegrationForClinic = (clinicId) => axios
    .put(`/api/admin/mango-office-integration-clinics/${clinicId}`)
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.left(error.response.data.title);
        }
        throw error;
      },
    );

  /**
   * @description Отключить интеграцию для клиники.
   * @param {string} clinicId Идентификатор клиники.
   * @returns {Promise<Maybe>}
   */
  this.disableIntegrationForClinic = (clinicId) => axios
    .delete(`/api/admin/mango-office-integration-clinics/${clinicId}`)
    .then(
      async (response) => Maybe.fromEmpty(response.data),
    );

  /**
   * @description Получить страницу клиник c подключенной интеграцией.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithIntegration = (page, match = '') => axios
    .get('/api/admin/mango-office-integration-clinics', { params: { match, page } })
    .then((response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить совпавшие клиники доступные для подключения.
   * @param {string} query Поисковый запрос.
   * @returns {Promise<Maybe>}
   */
  this.searchCandidates = (page, match = '') => axios
    .get('/api/admin/mango-office-integration-candidate-clinics', { params: { match, page } })
    .then((response) => Maybe.fromEmpty(response.data));
}
