import { Either, Maybe } from 'monet';

/**
 * @description Шлюз интеграции Medflex.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function MedflexGateway(axios) {
  /**
   * @description Получить клиники-кандидаты.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match) => axios
    .get('/api/admin/medflex-candidate-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить клиники подключенной интеграцией.
   * @param {number} page Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithAccount = (page, match = '') => axios
    .get('/api/admin/medflex/accounts', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Добавить аккаунт.
   * @param {int} candidateId
   * @param {string} misId
   * @param {string} tokenLpu
   * @param {string} departmentOid
   * @param {string} departmentName
   * @param {string} lpuUid
   * @returns {Promise<Either>}
   */
  this.addAccount = (
    candidateId,
    misId,
    tokenLpu,
    departmentOid,
    departmentName,
    lpuUid,
  ) => axios
    .post(
      `/api/admin/clinics/${candidateId}/medflex/accounts`,
      {
        mis_id: misId,
        token_lpu: tokenLpu,
        department_oid: departmentOid,
        department_name: departmentName,
        lpu_uid: lpuUid,
      },
    )
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Удалить интеграцию клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.removeIntegration = (clinicId) => axios
    .delete(`/api/admin/clinics/${clinicId}/medflex/remove`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить историю отправок документов клиники.
   * @param clinicId
   * @returns {Promise<Maybe<unknown>>}
   */
  this.getClinicHistorySendings = (clinicId) => axios
    .get(`/api/admin/clinics/${clinicId}/medflex/documents`)
    .then(async (response) => Maybe.fromEmpty(response.data));
}
