import { Either } from 'monet';
import LeftResponse from '@/storage/LeftResponse';

/**
 * @description Запрос на импорт пациентов.
 * @param {number} clinicId Идентификатор клиники.
 * @param {File} file Файл.
 * @constructor
 */
export function PatientListImportRequest(clinicId, file) {
  this.getClinicId = () => clinicId;
  this.toQuery = () => {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  };
}

/**
 * Новая категория для импорта
 * @param {string} name
 * @param {string} replacement
 * @param {bool} isNew
 * @constructor
 */
function NewCategory(
  name,
  replacement,
  isNew,
) {
  this.name = name;
  this.replacement = replacement;
  this.isNew = isNew;
}

/**
 * @description Запрос на загрузку цен.
 * @param {number} clinicId Идентификатор клиники.
 * @param {File} file Файл.
 * @constructor
 */
export function UploadSpreadsheetRequest(clinicId, file) {
  this.getClinicId = () => clinicId;
  this.toQuery = () => {
    const formData = new FormData();
    formData.append('file', file);
    return formData;
  };
}

/**
 * @description Запрос на импорт цен.
 * @param {number} clinicId Идентификатор клиники.
 * @param {number} importId Идентификатор импорта.
 * @param resetAllServices Перезаписать все услуги.
 * @constructor
 */
export function ImportSpreadsheetRequest(
  clinicId,
  importId,
  resetAllServices,
) {
  this.getClinicId = () => clinicId;
  this.getImportId = () => importId;
  this.resetAllServices = () => resetAllServices;
}

/**
 * @description Шлюз импорта.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function ImportGateway(axios) {
  /**
   * @description Отправить запрос на импорт пациентов.
   * @param {PatientListImportRequest} request Запрос.
   * @returns {Promise<Either>}
   */
  this.importPatients = (request) => axios
    .post(`/api/admin/clinics/${request.getClinicId()}/patients-import`, request.toQuery())
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        if (error.isAxiosError) {
          const { causes, title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        } throw error;
      },
    );
  /**
   * @description Отправить запрос на загрузку файла цен.
   * @param {UploadSpreadsheetRequest} request Запрос.
   * @returns {Promise<Either>}
   */
  this.uploadSpreadsheet = (request) => axios
    .post(`/api/admin/clinics/${request.getClinicId()}/price-spreadsheet-imports`, request.toQuery())
    .then(
      async (response) => {
        const { data } = response;
        const categories = data.categories.map((category) => new NewCategory(
          category.name,
          category.replacement,
          category.is_new,
        ));
        return Either.right({
          importId: data.import_id,
          categories,
          priceCount: data.price_count,
        });
      },
      async (error) => {
        if (error.isAxiosError && [400, 403].includes(error.response.status)) {
          const causes = error.response.data.causes || [];
          const { title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        }
        throw error;
      },
    );
  /**
   * @description Отправить запрос на импортирование таблицы
   * @param {ImportSpreadsheetRequest} request Запрос.
   * @return {Promise<Either>}
   */
  this.sendImportSpreadsheet = (request) => axios
    .put(`/api/admin/clinics/${request.getClinicId()}/price-spreadsheet-imports/${request.getImportId()}`, {
      reset_all_services: request.resetAllServices(),
    })
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        if (error.isAxiosError && [400, 403].includes(error.response.status)) {
          const causes = error.response.data.causes || [];
          const { title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        }
        throw error;
      },
    );
}
