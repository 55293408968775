import { Either, Maybe } from 'monet';

/**
 * @description Шлюз Green Api.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function GreenApiGateway(axios) {
  /**
   * @description Получить клиники-кандидаты.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match) => axios
    .get('/api/admin/green-api-candidate-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить клиники подключенной интеграцией.
   * @param {number} page Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithAccount = (page, match = '') => axios
    .get('/api/admin/green-api/accounts', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Добавить аккаунт.
   * @param {number} clinicId
   * @param {string} idInstance
   * @param {string} apiTokenInstance
   * @param {string} userName
   * @returns {Promise<Either>}
   */
  this.addAccount = (clinicId, idInstance, apiTokenInstance, userName) => axios
    .post(
      `/api/admin/clinics/${clinicId}/green-api/accounts`,
      { id_instance: idInstance, api_token_instance: apiTokenInstance, user_name: userName },
    )
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Удалить интеграцию клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.removeIntegration = (clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/green-api/remove`)
    .then(async (response) => Maybe.fromEmpty(response.data));
}
